import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import IvBottom from './components/ivBottom';
import SignUp from './components/signUp';
import SignIn from './components/signIn';
import AltSignUp from './components/altSignUp';
import AltSignIn from './components/altSignIn';
import Verify from './components/verify';
import MyBank from './components/myBank';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/mybank" element={<MyBank />} />
          <Route path="/v" element={<Verify />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/altsignin" element={<AltSignIn />} />
          <Route path="/altsignup" element={<AltSignUp />} />
        </Routes>
      </Router>
      <IvBottom />
    </div>
  )
}

function Index() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/altsignup', { replace: true });
    }, 100);
  });
  return null
}

export default App;