import '../css/ivBottom.css';
import logoIv from '../assets/Logotype.svg';

export default function IvBottom() {
    return (
        <div className="iv-bottom">
            <div className="ivBottomText">Powered by
            </div>
            <div className="iv-icon">
                <img src={logoIv} alt="" />
            </div>
        </div>
    )
}