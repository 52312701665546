import '../css/vectors.css';
import { Link } from 'react-router-dom';
import '../css/vectors.css';

export default function HeaderText() {
    return (
        <div className="wrapperHead">
            <div className="vectorsRap">
                <Link to="/signup">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="73" viewBox="0 0 48 73" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.8744 25.09L0 17.5999V64.0582L17.2787 72.7595V56.4683L32.1531 63.9584V47.6671L47.0275 55.1573V8.70135L29.7488 0V16.2912L14.8744 8.80112V25.09Z" fill="#0360F0" />
                    </svg>
                </Link>
            </div>
            {/* <Vectors /> */}
            <div className="containerHead">
                <div className="rapHeader">
                    <span className="headText1"><Link to="/signup">myBank</Link></span>
                </div>
            </div>
        </div>
    )
}