import '../css/myBank.css';
import logo from '../assets/mybank1.png'
import imgMobile from '../assets/mybankmobile.png'

export default function MyBank() {

    const logout = () => {
        window.location.replace("/");
    };

    return (
        <>
            <div className="rapImage">
                <div className="rapBtnLog">
                    <p id="logoutBtn" onClick={logout}>
                        Log Out
                    </p>
                    <div className="logoutSvg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M30 15.0056C30 6.73171 23.2683 0 15.0056 0C6.74296 0 0 6.73171 0 15.0056C0 23.2796 6.73171 30 14.9944 30C23.257 30 29.9887 23.2683 29.9887 15.0056H30ZM15.0056 27.7486C7.98124 27.7486 2.26266 22.03 2.26266 15.0056C2.26266 7.98124 7.96998 2.26266 14.9944 2.26266C22.0188 2.26266 27.7373 7.98124 27.7373 15.0169C27.7373 22.0525 22.0188 27.7599 14.9944 27.7599L15.0056 27.7486Z" fill="#003049" />
                            <path d="M19.4576 18.2472C20.2013 17.2131 20.6633 15.9607 20.6633 14.5819C20.6633 11.1694 17.9476 8.40027 14.6234 8.40027C11.2991 8.40027 8.58337 11.1694 8.58337 14.5819C8.58337 15.9607 9.03412 17.2131 9.77785 18.2472C7.79457 19.7524 6.6001 22.1079 6.6001 24.6931C6.6001 25.3251 7.10719 25.8421 7.72696 25.8421C8.34673 25.8421 8.85382 25.3251 8.85382 24.6931C8.85382 22.6939 9.84546 20.8899 11.4794 19.8328C12.4034 20.4073 13.474 20.7635 14.6234 20.7635C15.7728 20.7635 16.8545 20.4073 17.7673 19.8328C19.39 20.8899 20.3929 22.6939 20.3929 24.6931C20.3929 25.3251 20.9 25.8421 21.5198 25.8421C22.1395 25.8421 22.6466 25.3251 22.6466 24.6931C22.6466 22.1079 21.4521 19.7524 19.4689 18.2472H19.4576ZM10.8258 14.5819C10.8258 12.4333 12.5274 10.6983 14.6121 10.6983C16.6968 10.6983 18.3983 12.4448 18.3983 14.5819C18.3983 16.719 16.6968 18.4655 14.6121 18.4655C12.5274 18.4655 10.8258 16.719 10.8258 14.5819Z" fill="#003049" />
                        </svg>
                    </div>
                </div>
                <img className="myBankImg" src={logo} alt={"logo"} />
                <img className="myBankImgMobile" src={imgMobile} alt={"imgMobile"} />
            </div>
            <div className="outer">
            </div>
        </>
    )
}